import { lazy } from 'react'
import { RouteModel } from './route'

function retry(lazyComponent: any, retriesLeft = 5): Promise<any> {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error: any) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						reject(error)
						return
					}

					retry(lazyComponent, retriesLeft - 1).then(resolve, reject)
				}, 1000)
			})
	})
}

export const routes: RouteModel[] = [
	{
		path: '/',
		exact: true,
		component: lazy(() => retry(() => import('../pages/sign-in'))),
	},
	{
		path: '/privacy-policy',
		exact: true,
		component: lazy(() => retry(() => import('../pages/privacy-policy'))),
	},
	{
		path: '/terms-of-use',
		exact: true,
		component: lazy(() => retry(() => import('../pages/terms-of-use'))),
	},
	{
		path: '/confirm-email',
		exact: true,
		component: lazy(() => retry(() => import('../pages/sign-in'))),
	},
	{
		path: '/sign-up',
		exact: true,
		component: lazy(() => retry(() => import('../pages/sign-up'))),
	},
	{
		path: '/invalid-invite',
		exact: true,
		component: lazy(() => retry(() => import('../pages/invalid-invite'))),
	},
	{
		path: '/verify-account',
		exact: true,
		component: lazy(() => retry(() => import('../pages/verify-account'))),
	},
	{
		path: '/reset-password',
		exact: true,
		component: lazy(() => retry(() => import('../pages/reset-password'))),
	},
	{
		path: '/register',
		exact: true,
		component: lazy(() => retry(() => import('../pages/register'))),
	},
	{
		path: '/register-IBPA',
		exact: true,
		component: lazy(() => retry(() => import('../pages/register'))),
	},
	{
		path: '/register-COOP',
		exact: true,
		component: lazy(() => retry(() => import('../pages/register'))),
	},
	{
		path: '/app',
		component: lazy(() => retry(() => import('../pages/app'))),
		exact: false,
		routes: [
			{
				path: '/app/campaigns',
				component: lazy(() =>
					retry(() => import('../pages/app/campaigns'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/browse-ad-platforms',
				component: lazy(() =>
					retry(() => import('../pages/app/browse-ad-platforms'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/summary',
				component: lazy(() =>
					retry(() => import('../pages/app/summary'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/service',
				component: lazy(() =>
					retry(() => import('../pages/app/service'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/extend-checkout',
				component: lazy(() =>
					retry(() => import('../pages/app/extend-checkout'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/update-password',
				component: lazy(() => retry(() => import('../pages/app/update-password'))),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/create-campaign',
				component: lazy(() =>
					retry(() => import('../pages/app/create-campaign'))
				),
				exact: true,
				isProtected: true,
				routes: [
					{
						path: '/app/create-campaign/basics',
						component: lazy(() => retry(() => import('../pages/app/create-campaign/basics'))),
						exact: true,
						isProtected: true,
					}, 
					{
						path: '/app/create-campaign/audiences',
						component: lazy(() => retry(() => import('../pages/app/create-campaign/audiences'))),
						exact: true,
						isProtected: true,
					}, 
					{
						path: '/app/create-campaign/services',
						component: lazy(() => retry(() => import('../pages/app/create-campaign/services'))),
						exact: true,
						isProtected: true,
					},
					{
						path: '/app/create-campaign/setup',
						component: lazy(() => retry(() => import('../pages/app/create-campaign/setup'))),
						exact: true,
						isProtected: true,
					},
					{
						path: '/app/create-campaign/previews',
						component: lazy(() => retry(() => import('../pages/app/create-campaign/previews'))),
						exact: true,
						isProtected: true,
					}
				]
			},
			{
				path: '/app/shopping-cart',
				component: lazy(() =>
					retry(() => import('../pages/app/shopping-cart'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/checkout',
				component: lazy(() =>
					retry(() => import('../pages/app/checkout'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/thanks',
				component: lazy(() => retry(() => import('../pages/app/thanks'))),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/set-touchpoint',
				component: lazy(() =>
					retry(() => import('../pages/app/set-touchpoint'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/notifications-center',
				component: lazy(() =>
					retry(() => import('../pages/app/notification-center'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/reports',
				component: lazy(() =>
					retry(() => import('../pages/app/reports'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/settings/:option/:action?',
				component: lazy(() =>
					retry(() => import('../pages/app/settings'))
				),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/faqs/:categoryId?/:faqId?',
				component: lazy(() => retry(() => import('../pages/app/faq'))),
				exact: true,
				isProtected: true,
			},
			{
				path: '/app/contact-us',
				component: lazy(() =>
					retry(() => import('../pages/app/contact-us'))
				),
				exact: true,
				isProtected: false,
			},
			{
				path: '/app/editorial-submissions',
				component: lazy(() => retry(() => import("../pages/app/editorial-submissions"))),
				exact: true,
				isProtected: true,
			},
			//{
			//  path: '/app/pdf-viewer',
			//  component: lazy(() => retry(() => import("../pages/app/pdf-viewer"))),
			//  exact: true,
			//  isProtected: true,
			//}
		],
	},
	{
		path: '*',
		component: lazy(() => retry(() => import('../pages/404'))),
		exact: true,
		isProtected: false,
	},
]
