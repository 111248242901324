import moment from 'moment';
import { IEmailBlastAvailableDate } from '../models';

export const getMockAvailableDates = (): IEmailBlastAvailableDate[] => {
    let current = moment().startOf('day').utc();
    const end = current.clone().add(1, 'year');
    const results: IEmailBlastAvailableDate[] = [];
  
    while(current.isSameOrBefore(end)) {
        if (current.weekday() === 2 || current.weekday() === 4) {
            results.push({
                date: current.clone().toDate().toString(),
                available: Boolean(Math.round(Math.random())),
                price: current.weekday() === 2 ? 299 : undefined,
            } as IEmailBlastAvailableDate);
        }
        current.add(1, 'day');
    } 
  
    return results
}

export const getMockEmailBrand = (num: number): string => {
    if (num <= 3) return "Bookfinity";
    if (num <= 6) return "LittleInfinite";
    if (num <= 9) return "PagePairing";
    return "LiRomance";
}

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const getPlatformName = (platformId: number): { platform: string, id: string, name: string } => {
    switch (platformId) {
      case 0:
        return {
            platform: "Facebook",
            id: process.env.REACT_APP_FACEBOOK_PROMOTED_ID || "",
            name: "Facebook Ad"
        };
      case 1:
        return {
            platform: "GoogleSearch",
            id: process.env.REACT_APP_GOOGLE_SEARCH_ID || "",
            name: "Google Search Ad"
        };
      case 2:
        return {
            platform: "GoogleDisplay",
            id: process.env.REACT_APP_GOOGLE_DISPLAY_ID || "",
            name: "Google Display Ad"
        };
      case 5:
        return {
            platform: "Instagram",
            id: process.env.REACT_APP_INSTAGRAM_PROMOTED_ID || "",
            name: "Instagram Ad"
        };
      default:
        return {
            platform: "Unknown",
            id: "0000-0000-000000-000000-0000-0000",
            name: "Unknown Ad"
        };
    }
};
  