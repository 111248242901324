/**
 * Converts the numeric keys of an enumeration into an array of numeric values represented as powers of 2.
 * 
 * @param {object} enumeration - Enumeration with keys that represent numeric values.
 * @returns {number[]} An array of numeric values representing powers of 2, excluding non-numeric or falsy values.
 */
function enumToBitValues(enumeration: object): number[] {
  return Object.keys(enumeration).map(Number).filter(Boolean);
}

/**
 * Converts a boolean selection array into a numeric value (bitmask) based on an enumeration.
 * 
 * @param {object} enumeration - Enumeration that defines possible values as powers of 2.
 * @param {boolean[]} selection - Boolean array indicating selected (`true`) or not selected (`false`) elements.
 * @returns {number} The numeric value representing the selection as a bitmask.
 */
export function formValueToBit(enumeration: object, selection: boolean[]): number {
  const bits = enumToBitValues(enumeration);
  return selection.reduce(
    (total, selected, i) => total + (selected ? bits[i] : 0),
    0,
  );
}

/**
 * Converts a numeric value (bitmask) into a boolean array based on an enumeration.
 * 
 * @param {object} enumeration - Enumeration that defines possible values as powers of 2.
 * @param {number} bit - Numeric value representing the bitmask.
 * @returns {boolean[]} Boolean array indicating which values are present in the bitmask.
 */
export function bitToFormValue(enumeration: object, bit: number): boolean[] {
  const bits = enumToBitValues(enumeration);
  return bits.map((b) => (bit & b) === b);
}
