import * as types from './types'

const initialState: types.CampaignsState = {
  campaigns: [],
  currentPage: 0,
  totalCampaigns: 0,
  maxPages: 0,
  active: [],
  recomendedAudiences: [],
  books: [],
  totalBooks: 0,
  services: [],
  servicesPrices: {
    facebookAd: 0,
    googleDisplayAd: 0,
    googleSearchAd: 0,
    amazonPromotedAd: 0,
  },
  form: {
    data: {
      id: '',
      name: '',
      startDate: undefined,
      endDate: undefined,
      budget: 0,
      books: [],
      accountId: '',
      status: 5, //Draft
      marketingGoals: [false, false, false, false, false, false, false],
      recommendedAudiences: [],
      facebookTouchpoints: [],
      googleTouchpoints: [],
      amazonTouchpoints: [],
      emailBlastTouchpoints: [],
      shareAmazonBudget: false,
      endDateExtendPending: false,
      restartPending: false,
      campaignSetupProcess: 0,
      estimatedBudget: null,
      duration: null,
      webSiteUrl: null,
      pricePromotionType: null,
      pricePromotionValue: null
    },
    selectedAudiences: [],
    selectedServices: [],
    previews: {
      additionalServices: [],
      recommendedServices: []
    },
    step: {
      title: "Campaign Basics",
      progress: 5,
    },
    touchpoint: {
      products: [],
      prices: [],
      step: 'panel1',
      keywords: [],
    },
  },
  status: {
    loading: false,
    secondaryLoad: false,
    error: '',
  },
}

const campaignsReducer = (
  state: types.CampaignsState = initialState,
  action: any
): types.CampaignsState => {
  switch (action.type) {
    //START Form Actions(Campaign and Touchpoint Common)
    case types.SET_FORM_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload,
        },
      }

    case types.CLEAR_CAMPAIGN_SERVICES: {
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            facebookTouchpoints: [],
            amazonTouchpoints: [],
            googleTouchpoints: [],
            emailBlastTouchpoints: [],
          }
        }
      }
    }

    case types.RESET_FORM:
      return {
        ...state,
        form: initialState.form,
      }

    case types.RESET_BOOKS:
      return {
        ...state,
        books: initialState.books,
      }

    case types.SET_GUIDED_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
        },
      }
    }

    case types.SET_BYO_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
        },
      }
    }

    case types.GUIDED_CAMPAIGN_STEP_ONE:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            name: action.payload.name,
            books: action.payload.books,
            accountId: action.payload.accountId,
          },
          step: state.form.step,
        },
      }

    case types.GUIDED_CAMPAIGN_STEP_TWO:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            marketingGoals: action.payload.formGoals,
            budget: action.payload.budget,
            startDate: action.payload.startDate,
          },
          step: state.form.step,
        },
      }

    case types.GUIDED_CAMPAIGN_STEP_THREE:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            recommendedAudiences: action.payload,
          },
          selectedAudiences: action.payload,
          step: state.form.step,
        },
      }

    case types.GET_USER_BOOKS_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: true,
          error: initialState.status.error,
        },
      }

    case types.GET_USER_BOOKS_SUCCESS:
      return {
        ...state,
        books: action.payload.books,
        totalBooks: action.payload.totalBooks,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: initialState.status.error,
        },
      }

    case types.GET_USER_BOOKS_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: action.payload,
        },
      }

    case types.SET_AVAILABLE_PRICES: {
      return {
        ...state,
        form: {
          ...state.form,
          touchpoint: {
            ...state.form.touchpoint,
            prices: action.payload,
          },
        },
      }
    }

    //END
    //START Campaign Actions
    case types.GET_ACTIVE_CAMPAIGNS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          error: initialState.status.error,
          loading: true,
        },
      }
    }

    case types.GET_ACTIVE_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        active: action.payload,
        status: {
          ...state.status,
          loading: false,
        },
      }
    }

    case types.GET_ACTIVE_CAMPAIGNS_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          error: action.payload,
          loading: false,
        },
      }
    }

    case types.CREATE_CAMPAIGN_BASICS_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          error: initialState.status.error,
          loading: true,
        },
      }

    case types.CREATE_CAMPAIGN_BASICS_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            id: action.payload,
          },
        },
        status: {
          ...state.status,
          error: initialState.status.error,
          loading: false,
        },
      }

    case types.CREATE_CAMPAIGN_BASICS_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          error: action.payload,
          loading: false,
        },
      }

    case types.SAVE_CAMPAIGN_SETUP_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }

    case types.SAVE_CAMPAIGN_SETUP_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          previews: action.payload,
        },
        status: {
          ...state.status,
          loading: false,
          error: initialState.status.error,
        },
      }

    case types.SAVE_CAMPAIGN_SETUP_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }

    case types.GET_RECOMENDED_AUDIENCES_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: true,
          error: initialState.status.error,
        },
      }

    case types.GET_RECOMENDED_AUDIENCES_SUCCESS:
      return {
        ...state,
        recomendedAudiences: action.payload,
        status: {
          ...state.status,
          secondaryLoad: false,
        },
      }

    case types.GET_RECOMENDED_AUDIENCES_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: action.payload,
        },
      }

    case types.GET_ALL_RECOMENDED_AUDIENCES_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: true,
          error: initialState.status.error,
        },
      }

    case types.GET_ALL_RECOMENDED_AUDIENCES_SUCCESS:
      return {
        ...state,
        recomendedAudiences: action.payload,
        status: {
          ...state.status,
          secondaryLoad: false,
        },
      }

    case types.GET_ALL_RECOMENDED_AUDIENCES_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: action.payload,
        },
      }

    case types.UPDATE_RECOMENDED_AUDIENCES_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }

    case types.UPDATE_RECOMENDED_AUDIENCES_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
        },
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.UPDATE_RECOMENDED_AUDIENCES_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }

    case types.GET_MARKETING_SERVICES_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }

    case types.GET_MARKETING_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.GET_MARKETING_SERVICES_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }

    case types.ADD_MARKETING_SERVICES_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }

    case types.ADD_MARKETING_SERVICES_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          selectedServices: action.payload,
        },
        status: {
          ...state.status,
          loading: false,
          error: initialState.status.error,
        },
      }

    case types.ADD_MARKETING_SERVICES_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }

      case types.ADD_MARKETING_SERVICES_REQUEST:
        return {
          ...state,
          status: {
            ...state.status,
            loading: true,
            error: initialState.status.error,
          },
        }
  
      case types.ADD_MARKETING_SERVICES_SUCCESS:
        return {
          ...state,
          status: {
            ...state.status,
            loading: false,
            error: initialState.status.error,
          },
        }
  
      case types.ADD_MARKETING_SERVICES_FAILED:
        return {
          ...state,
          status: {
            ...state.status,
            loading: false,
            error: action.payload,
          },
        }

    case types.GET_CAMPAIGN_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }

    case types.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.payload,
          },
        },
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.GET_CAMPAIGN_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }

    case types.DELETE_CAMPAIGN_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }

    case types.DELETE_CAMPAIGN_SUCCESS: {
      const campaigns = state.campaigns.filter(c => c.id !== action.payload)
      return {
        ...state,
        campaigns,
        form: initialState.form,
        status: {
          ...state.status,
          loading: false,
        },
      }
    }

    case types.DELETE_CAMPAIGN_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }

    case types.MARK_FAVORITE_CAMPAIGN: {
      const newCampaigns = state.campaigns;
      const index = newCampaigns.indexOf(action.payload);
      const newActive = state.active;
      const activeIndex = newActive.findIndex((c) => c.id === action.payload.id);

      if (index >= 0) {
        newCampaigns[index].favorite = !newCampaigns[index].favorite
      }

      if (activeIndex >= 0) {
        newActive[activeIndex].favorite = !newActive[activeIndex].favorite
      }

      return {
        ...state,
        campaigns: newCampaigns,
        active: newActive,
      }
    }

    case types.PROCESS_CAMPAIGN_CHECKOUT_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }
    }

    case types.PROCESS_CAMPAIGN_CHECKOUT_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
        },
      }
    }

    case types.PROCESS_CAMPAIGN_CHECKOUT_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }
    }

    case types.GET_CAMPAIGN_BY_ID_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error,
        },
      }
    }

    case types.GET_CAMPAIGN_BY_ID_SUCCESS: {
      const filtered = state.campaigns.filter(c => c.id !== action.payload.id)

      return {
        ...state,
        campaigns: [...filtered, action.payload],
        status: {
          ...state.status,
          loading: false,
        },
      }
    }

    case types.GET_CAMPAIGN_BY_ID_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        },
      }
    }

    case types.GET_FINAL_KEYWORDS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: true,
          error: initialState.status.error,
        },
      }
    }

    case types.GET_FINAL_KEYWORDS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
        },
        form: {
          ...state.form,
          touchpoint: {
            ...state.form.touchpoint,
            keywords: action.payload,
          },
        },
      }
    }

    case types.GET_FINAL_KEYWORDS_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: action.payload,
        },
      }
    }

    case types.TOGGLE_SHARE_AMAZON_BUDGET: {
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            shareAmazonBudget: !state.form.data.shareAmazonBudget
          }
        }
      }
    }

    case types.CLONE_CAMPAIGN_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: true
        }
      }
    }

    case types.CLONE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false
        }
      }
    }

    case types.CLONE_CAMPAIGN_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: action.payload,
        }
      }
    }

    default:
      return state
  }
}

export default campaignsReducer
