import { BasePriceModel, IAmazonTouchpoint, IEmailBlastTouchpoint, IFacebookTouchpoint, IGoogleTouchpoint } from '../models';


export const getTypeByService = (n: number) => {
	switch (n) {
		case 0:
			return 'Facebook'

		case 1:
			return 'Search'

		case 2:
			return 'Display'

		case 3:
			return 'AmazonSP'

		case 4:
			return 'EBlast'

		case 5:
			return 'Instagram'

		default:
			return 'unknown'
	}
};


function dateDiff(start: any, end: any) {
	const startDate = Date.parse(start);
	const endDate = Date.parse(end)
	const diff = endDate - startDate;
	return Math.round(diff / (1000 * 60 * 60 * 24)) + 1;
}


type Touchpoint = IFacebookTouchpoint | IGoogleTouchpoint | IAmazonTouchpoint | IEmailBlastTouchpoint;

export function getTouchpointTotal(touchpoint: Touchpoint, defaultPrice?: BasePriceModel) {
	const { startDate, endDate, productPrice, servicePrice } = touchpoint;
	const periodOfTime = dateDiff(startDate, endDate);
	let finalPrice = 0;

	if (productPrice) {
		finalPrice = productPrice.price * periodOfTime;
	}

	if (servicePrice) {
		finalPrice = servicePrice.price * periodOfTime;
	}

	if (finalPrice === 0  && defaultPrice && startDate && endDate) {
		finalPrice = defaultPrice.price * periodOfTime;
	}

	return finalPrice
}

export function getTouchpointsSubTotal(touchpoints: any[]) {
	const totals = touchpoints.map(touchpoint => touchpoint.productPrice && getTouchpointTotal(touchpoint));
	return totals.reduce((acc, current) => acc + current, 0)
}

export function disableCheckout(touchpoints: any[]) {
	const completed = touchpoints.filter(touchpoint => touchpoint.productPrice);
	return completed.length === touchpoints.length;
}

function processGoogleTouchpoints(googleTouchpoints: Array<IGoogleTouchpoint>) {
	googleTouchpoints.forEach((element: { startDate: Date; endDate: Date }) => {
		processTouchpoint(element);
	});
}

function processFacebookTouchpoints(facebookTouchpoints: Array<IFacebookTouchpoint>) {
	facebookTouchpoints.forEach((element: { startDate: Date; endDate: Date }) => {
		processTouchpoint(element);
	});
}

function processAmazonTouchpoints(amazonTouchpoints: Array<IAmazonTouchpoint>) {
	amazonTouchpoints.forEach((element: { startDate: Date; endDate: Date }) => {
		processTouchpoint(element);
	});
}

export function processCampaignTouchpoints(res: any) {

	if (res.startDate) {
		res.startDate = new Date(Date.parse(res.startDate.toString() + 'Z'));
	}
	if (res.endDate) {
		res.endDate = new Date(Date.parse(res.endDate.toString() + 'Z'));
	}

	processFacebookTouchpoints(res.facebookTouchpoints);
	processGoogleTouchpoints(res.googleTouchpoints);
	processAmazonTouchpoints(res.amazonTouchpoints);
}

export function processTouchpoint(res: any) {
    if (res.selectedProducts && res.selectedProducts.length === 0) {
        res.selectedProducts = res.products
            .filter((p: any) => p.productPrices && p.productPrices.filter((pp: any) => !pp.deleted).length > 0)
            .map((p: any) => p.id);
    }

    const toUTCDate = (dateStr: string) => {
        const date = new Date(dateStr);
        return new Date(date.toISOString()); // Convert to UTC
    };

    if (res.startDate) {
        res.startDate = toUTCDate(res.startDate);
    }
    if (res.endDate) {
        res.endDate = toUTCDate(res.endDate);
    }
}

export function getTouchpointStatus(status: number): string {
	switch (status) {
		case 0:
			return 'ERROR'
		case 1:
			return 'ON REVIEW'
		case 2:
			return 'ON REVIEW'
		case 3:
			return 'SCHEDULED'
		case 4:
			return 'RUNNING'
		case 5:
			return 'DRAFT'
		case 6:
			return 'FINISHED'
		case 7:
			return 'CANCELLED'
		default:
			return ''
	}
}
