import { IAmazonTouchpoint, IEmailBlastTouchpoint, IFacebookTouchpoint, IGoogleTouchpoint, NotificationModel } from ".";

export enum Goals {
    LNT = 1,
    APP = 2,
    ISPT = 4,
    PE = 8,
    RMPP = 16,
    IEL = 32,
    AP = 64
}

export type CampaignPageResponse = {
    content: Array<CardCampaignModel>
    campaignStatus: Array<{ status: number, count: number }>
    currentPage: number
    maxPages: number
    totalCampaigns: number
}

export type CardCampaignModel = {
    id: string
    name: string
    books: Array<BookModel>
    startDate: Date
    endDate: Date
    accountId: string
    status: number
    folderId: null | string
    favorite: boolean
    touchpoints: Array<CardTouchpointItem>
}

export type CardTouchpointItem = {
    id: string
    service: string | number
    clicks: number
    status: number
    startDate: Date
    endDate: Date
    enableExtend: boolean
    enableRestart: boolean
}

export type CampaignModel = {
    id: string
    name: string
    startDate: Date
    endDate: Date
    budget: number
    books: Array<BookModel>
    accountId: string
    status: number
    marketingGoals: number
    promoting: number
    favorite: boolean
    recommendedAudiences: RecomendedAudienceModel[]
    facebookTouchpoints: IFacebookTouchpoint[]
    googleTouchpoints: IGoogleTouchpoint[]
    amazonTouchpoints: IAmazonTouchpoint[]
    emailBlastTouchpoints: IEmailBlastTouchpoint[]
    completePercentage: number
    updatedDate: Date
    notifications: NotificationModel[]
    endDateExtendPending: boolean
    statsResume: IStatsResume
    campaignInvoices: InvoiceModel[]
    folderId: null | string
    restartPending: boolean
    campaignSetupProcess: number
    estimatedBudget: number | null
    duration: number  | null
    pricePromotionType: null | number
    pricePromotionValue: null | number
    webSiteUrl: null | string
}
export type InvoiceModel = {
    id: string
    campaignID: string
    stripeInvoiceId: string
    stripeCustomerId: string
    promotionCode: string
    zipCode: string
    total: number
    subTotal: number
    tax: number
    paymentStatus: number
}

export type CampaignFormModel = {
    id: string
    name: string
    startDate: Date | undefined
    endDate: Date | undefined
    budget: number
    books: Array<BookModel>
    accountId: string
    status: number
    marketingGoals: boolean[]
    recommendedAudiences: RecomendedAudienceModel[]
    facebookTouchpoints: IFacebookTouchpoint[]
    googleTouchpoints: IGoogleTouchpoint[]
    amazonTouchpoints: IAmazonTouchpoint[]
    emailBlastTouchpoints: IEmailBlastTouchpoint[]
    endDateExtendPending: boolean
    shareAmazonBudget: boolean
    restartPending: boolean
    estimatedBudget: null | number
    duration: null | number
    campaignSetupProcess: number
    pricePromotionType: null | number
    pricePromotionValue: null | number
    webSiteUrl: null | string
}

export type BookModel = {
    id: string
    isbn: string
    title: string
    author: string
    genre: string
    type: string
    pubDate: string
    bookCoverURL: string
    asin: string
    sku: string
    publishedDate: string
    primaryNarrative: string
}

export type RecomendedAudienceModel = {
    id: string
    name: string
    description: string
    locations: string | null
    interest: string | null
    size?: string
    salesforcePopulation: number
    facebookAudienceValidated: number
    facebookPopulation: number
    googleAudienceValidated: boolean
    googleDisplayPopulation: number
    emailBlastPopulation: number
    emailBlastAudienceValidated: boolean
    emailBlastAudienceId: string
    servicesLabel: string
    services: ServiceModel[]
}

export type ServiceModel = {
    id: string
    name: string
    description: string
    type: number
    startingAt: number
    audiences: Array<string>
}

export type ProductModel = {
    id: string
    audienceName: string
    cpa: number
    population: number
    enable: boolean
    productPrices: ProductPriceModel[]
    recommendedAudience: RecomendedAudienceModel
    service: ServiceModel | null
    serviceId: string
    sku: string
}

export interface BasePriceModel {
    id: string
    budget: number
    clickTarget: string
    impressions: string
    price: number
    deleted: boolean
    enable: boolean
}

export interface ProductPriceModel extends BasePriceModel {
    productId: string
    salesforceId: string
}

export type AmazonKeywordModel = {
    id?: string
    matchType: null | string
    keywordText: string
}

export type FinalKeywordModel = {
    seedKeyword: string
    finalWord: string
    CPC: number
    CMP: number
    MSV: number
}

export type FilterOption = {
    id?: string
    title?: string
    ocurrences?: Array<string>
    name?: string
    campaignId?: string
    group?: string
}

export type TouchpointFormError = {
    id: string
    field: string
    desc: string
    step: number
}

export type GoogleValidationResponseItem = {
    Message: string
    Trace: string
    Code: string
}

export type GoogleValidationItem = {
    trace: string
    field: string
    message: string
    code: string
}

export interface IExtendedCampaignSummaryRequest {
    campaignId: string
    country?: string
    state?: string
    city?: string
    street?: string
    zipCode?: string
    promotionCode?: string
    stripeCustomerId?: string
    touchpointsToExtend?: ITouchpointExtendedRequestItem[]
}

export interface ITouchpointExtendedRequestItem {
    touchpointId: string
    newEndDate: Date
    newStartDate?: Date
}

export interface IStatsResume {
    aboveTargetClicks: number
    aboveTargetImpressions: number
    clicks: number
    cpc: number
    cpcArray: number[]
    ctr: number
    cumulativeClicks: number[]
    cumulativeImpressions: number[]
    diffWpdClicks: number
    diffWpdCpc: number
    diffWpdCtr: number
    diffWpdImpressions: number
    expectedClicks: number
    expectedClicksArray: number[]
    expectedImpressions: number[]
    impressions: number
    lastUpdate: Date
    minTargetClicks: number
    minTargetImpressions: number
    maxTargetClicks: number
    maxTargetImpressions: number
    totalSpend: number
}


export enum CampaignSetupProcess {
    manual = 0,
    automatic = 1
}

export enum TouchpointAIOptimization {
    audiences = 1,
    facebookPage = 2,
    content = 4,
    kewords = 8
}

export enum EstimatedBudget {
    l200 = 1,
    b200_500 = 2,
    b500_1000 = 3,
    g1000 = 4
}

export enum Duration {
    twoWeeksOrLess = 1,
    aboutAMonth = 2,
    severalMonthsToAYear = 3
}



