import {
  CampaignModel,
  BookModel,
  CampaignFormModel,
  RecomendedAudienceModel,
  ServiceModel,
  FinalKeywordModel,
} from '../../../models'

export type CampaignsState = {
  campaigns: Array<CampaignModel>
  currentPage: number
  maxPages: number
  totalCampaigns: number
  active: Array<any>
  recomendedAudiences: Array<RecomendedAudienceModel>
  books: Array<BookModel>
  totalBooks: number
  services: Array<ServiceModel>
  servicesPrices: {
    facebookAd: number
    googleDisplayAd: number
    googleSearchAd: number
    amazonPromotedAd: number
  }
  form: {
    data: CampaignFormModel
    selectedAudiences: Array<RecomendedAudienceModel>
    selectedServices: Array<ServiceModel>,
    previews:{
      additionalServices: number[],
      recommendedServices: number[]
    }
    step: {
      title: string,
      progress: number,
    }
    touchpoint: {
      step: string
      products: any[]
      prices: any[]
      keywords: FinalKeywordModel[]
    }
  }
  status: {
    loading: boolean
    error: string
    secondaryLoad: boolean
  }
}

/**
 *          Index(Ctrl+F and Section)
 *
 *
 *          + Form Actions(Campaign and Touchpoint Common)
 *          + Campaign Actions
 *          + FacebookTouchpoint
 *          + GoogleTouchpoint(Paid Search)
 *
 *          + Checkout
 */

//-------------START Form Actions(Campaign and Touchpoint Common)

export const SET_FORM_STEP = 'SET_FORM_STEP'

type setFormStep = {
  type: typeof SET_FORM_STEP
  payload: {
    title: string,
    progress: number,
  }
}

export const SET_GUIDED_FORM = 'SET_GUIDED_FORM'

type setGuidedForm = {
  type: typeof SET_GUIDED_FORM
}

export const SET_BYO_FORM = 'SET_BYO_FORM'

type setBYOForm = {
  type: typeof SET_BYO_FORM
}

export const CLEAR_CAMPAIGN_SERVICES = "CLEAR_CAMPAIGN_SERVICES";

type clearServices = {
  type: typeof CLEAR_CAMPAIGN_SERVICES,
}

export const SET_AVAILABLE_PRICES = 'SET_AVAILABLE_PRICES'

type setAvailablePrices = {
  type: typeof SET_AVAILABLE_PRICES
  payload: any[]
}

export const RESET_FORM = 'RESET_FORM';

export const RESET_BOOKS = "RESET_BOOKS";

type resetForm = {
  type: typeof RESET_FORM
}

type resetBooks = {
  type: typeof RESET_BOOKS
}

export const GUIDED_CAMPAIGN_STEP_ONE = 'GUIDED_CAMPAIGN_STEP_ONE'

type guidedCampaignStepOne = {
  type: typeof GUIDED_CAMPAIGN_STEP_ONE
  payload: {
    name: string
    books: Array<BookModel>
    accountId: string
  }
}

export const GUIDED_CAMPAIGN_STEP_TWO = 'SET_FORM_STEP_TWO'

type guidedCampaignStepTwo = {
  type: typeof GUIDED_CAMPAIGN_STEP_TWO
  payload: {
    marketingGoals: Array<boolean>
    promoting: Array<boolean>
    startDate: Date
    budget: number
  }
}

export const GUIDED_CAMPAIGN_STEP_THREE = 'GUIDED_CAMPAIGN_STEP_THREE'

type guidedCampaignStepThree = {
  type: typeof GUIDED_CAMPAIGN_STEP_THREE
  payload: Array<RecomendedAudienceModel>
}

export const GET_USER_BOOKS_REQUEST = 'GET_USER_BOOKS_REQUEST'
export const GET_USER_BOOKS_SUCCESS = 'GET_USER_BOOKS_SUCCESS'
export const GET_USER_BOOKS_FAILED = 'GET_USER_BOOKS_FAILED'

type getUserBooksRequest = {
  type: typeof GET_USER_BOOKS_REQUEST
}

type getUserBooksSuccess = {
  type: typeof GET_USER_BOOKS_SUCCESS
  payload: {
    books: Array<BookModel>
    totalBooks: number
  }
}

type getUserBooksFailed = {
  type: typeof GET_USER_BOOKS_FAILED
  payload: string
}

//---------END
//---------START Campaign Actions

export const GET_ACTIVE_CAMPAIGNS_REQUEST = 'GET_ACTIVE_CAMPAIGNS_REQUEST'
export const GET_ACTIVE_CAMPAIGNS_SUCCESS = 'GET_ACTIVE_CAMPAIGNS_SUCCESS'
export const GET_ACTIVE_CAMPAIGNS_FAILED = 'GET_ACTIVE_CAMPAIGNS_FAILED'

type getActiveCampaignsRequest = {
  type: typeof GET_ACTIVE_CAMPAIGNS_REQUEST
}

type getActiveCampaignsSuccess = {
  type: typeof GET_ACTIVE_CAMPAIGNS_SUCCESS
  payload: any[]
}

type getActiveCampaignsFailed = {
  type: typeof GET_ACTIVE_CAMPAIGNS_FAILED
  payload: string
}

export const GET_CAMPAIGN_BY_ID_REQUEST = 'GET_CAMPAIGN_BY_ID_REQUEST'
export const GET_CAMPAIGN_BY_ID_SUCCESS = 'GET_CAMPAIGN_BY_ID_SUCCESS'
export const GET_CAMPAIGN_BY_ID_FAILED = 'GET_CAMPAIGN_BY_ID_FAILED'

type getCampaignByIdRequest = {
  type: typeof GET_CAMPAIGN_BY_ID_REQUEST
}

type getCampaignByIdSuccess = {
  type: typeof GET_CAMPAIGN_BY_ID_SUCCESS
  payload: any
}

type getCampaignByIdFailed = {
  type: typeof GET_CAMPAIGN_BY_ID_FAILED
  payload: string
}

export const CREATE_CAMPAIGN_BASICS_REQUEST = 'CREATE_CAMPAIGN_BASICS_REQUEST'
export const CREATE_CAMPAIGN_BASICS_SUCCESS = 'CREATE_CAMPAIGN_BASICS_SUCCESS'
export const CREATE_CAMPAIGN_BASICS_FAILED = 'CREATE_CAMPAIGN_BASICS_FAILED'

type createCampaignBasicsRequest = {
  type: typeof CREATE_CAMPAIGN_BASICS_REQUEST
}

type createCampaignBasicsSuccess = {
  type: typeof CREATE_CAMPAIGN_BASICS_SUCCESS
  payload: string
}

type createCampaignBasicsFailed = {
  type: typeof CREATE_CAMPAIGN_BASICS_FAILED
  payload: string
}

export const SAVE_CAMPAIGN_SETUP_REQUEST = 'SAVE_CAMPAIGN_SETUP_REQUEST'
export const SAVE_CAMPAIGN_SETUP_SUCCESS = 'SAVE_CAMPAIGN_SETUP_SUCCESS'
export const SAVE_CAMPAIGN_SETUP_FAILED = 'SAVE_CAMPAIGN_SETUP_FAILED'

type saveCampaignSetupRequest = {
  type: typeof SAVE_CAMPAIGN_SETUP_REQUEST,
}

type saveCampaignSetupSuccess = {
  type: typeof SAVE_CAMPAIGN_SETUP_SUCCESS,
  payload: {
    additionalServices: string[],
    recommendedServices: string[]
  }
}

type saveCampaignSetupFailed = {
  type: typeof SAVE_CAMPAIGN_SETUP_FAILED
  payload: string
}

export const GET_RECOMENDED_AUDIENCES_REQUEST =
  'GET_RECOMENDED_AUDIENCES_REQUEST'
export const GET_RECOMENDED_AUDIENCES_SUCCESS =
  'GET_RECOMENDED_AUDIENCES_SUCCESS'
export const GET_RECOMENDED_AUDIENCES_FAILED = 'GET_RECOMENDED_AUDIENCES_FAILED'

type getRecomendedAudiencesRequest = {
  type: typeof GET_RECOMENDED_AUDIENCES_REQUEST
}

type getRecomendedAudiencesSuccess = {
  type: typeof GET_RECOMENDED_AUDIENCES_SUCCESS
  payload: any
}

type getRecomendedAudiencesFailed = {
  type: typeof GET_RECOMENDED_AUDIENCES_FAILED
  payload: string
}

export const GET_ALL_RECOMENDED_AUDIENCES_REQUEST =
  'GET_ALL_RECOMENDED_AUDIENCES_REQUEST'
export const GET_ALL_RECOMENDED_AUDIENCES_SUCCESS =
  'GET_ALL_RECOMENDED_AUDIENCES_SUCCESS'
export const GET_ALL_RECOMENDED_AUDIENCES_FAILED = 'GET_ALL_RECOMENDED_AUDIENCES_FAILED'

type getAllRecomendedAudiencesRequest = {
  type: typeof GET_ALL_RECOMENDED_AUDIENCES_REQUEST
}

type getAllRecomendedAudiencesSuccess = {
  type: typeof GET_ALL_RECOMENDED_AUDIENCES_SUCCESS
  payload: any
}

type getAllRecomendedAudiencesFailed = {
  type: typeof GET_ALL_RECOMENDED_AUDIENCES_FAILED
  payload: string
}

export const UPDATE_RECOMENDED_AUDIENCES_REQUEST =
  'UPDATE_RECOMENDED_AUDIENCES_REQUEST'
export const UPDATE_RECOMENDED_AUDIENCES_SUCCESS =
  'UPDATE_RECOMENDED_AUDIENCES_SUCCESS'
export const UPDATE_RECOMENDED_AUDIENCES_FAILED =
  'UPDATE_RECOMENDED_AUDIENCES_FAILED'

type updateRecomendedAudiencesRequest = {
  type: typeof UPDATE_RECOMENDED_AUDIENCES_REQUEST
}

type updateRecomendedAudiencesSuccess = {
  type: typeof UPDATE_RECOMENDED_AUDIENCES_SUCCESS
  payload: any
}

type updateRecomendedAudiencesFailed = {
  type: typeof UPDATE_RECOMENDED_AUDIENCES_FAILED
  payload: string
}

export const ADD_MARKETING_SERVICES_REQUEST = 'ADD_MARKETING_SERVICES_REQUEST'
export const ADD_MARKETING_SERVICES_SUCCESS = 'ADD_MARKETING_SERVICES_SUCCESS'
export const ADD_MARKETING_SERVICES_FAILED = 'ADD_MARKETING_SERVICES_FAILED'

type addMarketingServicesRequest = {
  type: typeof ADD_MARKETING_SERVICES_REQUEST
}

type addMarketingServicesSuccess = {
  type: typeof ADD_MARKETING_SERVICES_SUCCESS
  payload: Array<ServiceModel>
}

type addMarketingServicesFailed = {
  type: typeof ADD_MARKETING_SERVICES_FAILED
  payload: string
}

export const ADD_AI_MARKETING_SERVICES_REQUEST = 'ADD_AI_MARKETING_SERVICES_REQUEST'
export const ADD_AI_MARKETING_SERVICES_SUCCESS = 'ADD_AI_MARKETING_SERVICES_SUCCESS'
export const ADD_AI_MARKETING_SERVICES_FAILED = 'ADD_AI_MARKETING_SERVICES_FAILED'

type addAIMarketingServicesRequest = {
  type: typeof ADD_AI_MARKETING_SERVICES_REQUEST
}

type addAIMarketingServicesSuccess = {
  type: typeof ADD_AI_MARKETING_SERVICES_SUCCESS
  payload: Array<string>
}

type addAIMarketingServicesFailed = {
  type: typeof ADD_AI_MARKETING_SERVICES_FAILED
  payload: string
}

export const GET_MARKETING_SERVICES_REQUEST = 'GET_MARKETING_SERVICES_REQUEST'
export const GET_MARKETING_SERVICES_SUCCESS = 'GET_MARKETING_SERVICES_SUCCESS'
export const GET_MARKETING_SERVICES_FAILED = 'GET_MARKETING_SERVICES_FAILED'

type getMarketingServicesRequest = {
  type: typeof GET_MARKETING_SERVICES_REQUEST
}

type getMarketingServicesSuccess = {
  type: typeof GET_MARKETING_SERVICES_SUCCESS
  payload: Array<ServiceModel>
}

type getMarketingServicesFailed = {
  type: typeof GET_MARKETING_SERVICES_FAILED
  payload: string
}

export const GET_CAMPAIGN_REQUEST = 'GET_CAMPAIGN_REQUEST'
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS'
export const GET_CAMPAIGN_FAILED = 'GET_CAMPAIGN_FAILED'

type getCampaignRequest = {
  type: typeof GET_CAMPAIGN_REQUEST
}

type getCampaignSuccess = {
  type: typeof GET_CAMPAIGN_SUCCESS
  payload: any
}

type getCampaignFailed = {
  type: typeof GET_CAMPAIGN_FAILED
  payload: string
}

export const UPDATE_CAMPAIGN_REQUEST = 'UPDATE_CAMPAIGN_REQUEST'
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS'
export const UPDATE_CAMPAIGN_FAILED = 'UPDATE_CAMPAIGN_FAILED'

type updateCampaignRequest = {
  type: typeof UPDATE_CAMPAIGN_REQUEST
}

type updateCampaignSuccess = {
  type: typeof UPDATE_CAMPAIGN_SUCCESS
}

type updateCampaignFailed = {
  type: typeof UPDATE_CAMPAIGN_FAILED
  payload: string
}

export const DELETE_CAMPAIGN_REQUEST = 'DELETE_CAMPAIGN_REQUEST'
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS'
export const DELETE_CAMPAIGN_FAILED = 'DELETE_CAMPAIGN_FAILED'

type deleteCampaignRequest = {
  type: typeof DELETE_CAMPAIGN_REQUEST
}

type deleteCampaignSuccess = {
  type: typeof DELETE_CAMPAIGN_SUCCESS
  payload: any
}

type deleteCampaignFailed = {
  type: typeof DELETE_CAMPAIGN_FAILED
  payload: string
}

//-------------END Campaign Actions
//-------------START FacebookTouchpoint

export const DELETE_FB_TOUCHPOINT_REQUEST = 'DELETE_FB_TOUCHPOINT_REQUEST'
export const DELETE_FB_TOUCHPOINT_SUCCESS = 'DELETE_FB_TOUCHPOINT_SUCCESS'
export const DELETE_FB_TOUCHPOINT_FAILED = 'DELETE_FB_TOUCHPOINT_FAILED'

type deleteFbTouchpointRequest = {
  type: typeof DELETE_FB_TOUCHPOINT_REQUEST
}

type deleteFbTouchpointSuccess = {
  type: typeof DELETE_FB_TOUCHPOINT_SUCCESS
  payload: any
}

type deleteFbTouchpointFailed = {
  type: typeof DELETE_FB_TOUCHPOINT_FAILED
  payload: string
}

export const MARK_FAVORITE_CAMPAIGN = 'MARK_FAVORITE_CAMPAIGN';

type markCampaignFavorite = {
  type: typeof MARK_FAVORITE_CAMPAIGN,
  payload: any
}


//------------ END FacebookTouchpoint
//------------ START GoogleTouchpoint(Paid Search) Delete!

export const GET_FINAL_KEYWORDS_REQUEST = 'GET_FINAL_KEYWORDS_REQUEST'
export const GET_FINAL_KEYWORDS_SUCCESS = 'GET_FINAL_KEYWORDS_SUCCESS'
export const GET_FINAL_KEYWORDS_FAILED = 'GET_FINAL_KEYWORDS_FAILED'

type getFinalKeywordsRequest = {
  type: typeof GET_FINAL_KEYWORDS_REQUEST
}

type getFinalKeywordsSuccess = {
  type: typeof GET_FINAL_KEYWORDS_SUCCESS
  payload: FinalKeywordModel[]
}

type getFinalKeywordsFailed = {
  type: typeof GET_FINAL_KEYWORDS_FAILED
  payload: string
}

//------------ END GoogleTouchpoint(Paid Search)

export const PROCESS_CAMPAIGN_CHECKOUT_REQUEST =
  'PROCESS_CAMPAIGN_CHECKOUT_REQUEST'
export const PROCESS_CAMPAIGN_CHECKOUT_SUCCESS =
  'PROCESS_CAMPAIGN_CHECKOUT_SUCCESS'
export const PROCESS_CAMPAIGN_CHECKOUT_FAILED =
  'PROCESS_CAMPAIGN_CHECKOUT_FAILED'

type processCampaignCheckoutRequest = {
  type: typeof PROCESS_CAMPAIGN_CHECKOUT_REQUEST
}

type processCampaignCheckoutSuccess = {
  type: typeof PROCESS_CAMPAIGN_CHECKOUT_SUCCESS
}

type processCampaignCheckoutFailed = {
  type: typeof PROCESS_CAMPAIGN_CHECKOUT_FAILED
  payload: string
}

//------>ENDS Checkout


export const TOGGLE_SHARE_AMAZON_BUDGET = "TOGGLE_SHARE_AMAZON_BUDGET";

type toggleShareAmazonBudget = {
  type: typeof TOGGLE_SHARE_AMAZON_BUDGET,
}

export const CLONE_CAMPAIGN_REQUEST = "CLONE_CAMPAIGN_REQUEST"; 
export const CLONE_CAMPAIGN_SUCCESS = "CLONE_CAMPAIGN_SUCCESS"; 
export const CLONE_CAMPAIGN_FAILED = "CLONE_CAMPAIGN_FAILED"; 

type cloneCampaignRequest = {
  type: typeof CLONE_CAMPAIGN_REQUEST
}

type cloneCampaignSuccess = {
  type: typeof CLONE_CAMPAIGN_SUCCESS
}

type cloneCampaignFailed = {
  type: typeof CLONE_CAMPAIGN_FAILED,
  payload: string
}

//------>ENDS ServicePrices

export type DispatchTypes =
  //----> Form Actions(Campaign and Touchpoint Common)
  | resetForm
  | resetBooks
  | setFormStep
  | guidedCampaignStepOne
  | clearServices
  | guidedCampaignStepTwo
  | guidedCampaignStepThree
  | getUserBooksRequest
  | getUserBooksSuccess
  | getUserBooksFailed
  | setGuidedForm
  | setBYOForm

  //----> Campaign Actions
  | getCampaignRequest
  | getCampaignSuccess
  | getCampaignFailed
  | getActiveCampaignsRequest
  | getActiveCampaignsSuccess
  | getActiveCampaignsFailed
  | getCampaignByIdRequest
  | getCampaignByIdSuccess
  | getCampaignByIdFailed
  | updateCampaignRequest
  | updateCampaignSuccess
  | updateCampaignFailed
  | deleteCampaignRequest
  | deleteCampaignSuccess
  | deleteCampaignFailed
  | updateRecomendedAudiencesRequest
  | updateRecomendedAudiencesSuccess
  | updateRecomendedAudiencesFailed
  | createCampaignBasicsRequest
  | createCampaignBasicsSuccess
  | createCampaignBasicsFailed
  | saveCampaignSetupRequest
  | saveCampaignSetupSuccess
  | saveCampaignSetupFailed
  | getRecomendedAudiencesRequest
  | getRecomendedAudiencesSuccess
  | getRecomendedAudiencesFailed
  | getAllRecomendedAudiencesRequest
  | getAllRecomendedAudiencesSuccess
  | getAllRecomendedAudiencesFailed
  | getMarketingServicesRequest
  | getMarketingServicesSuccess
  | getMarketingServicesFailed
  | addMarketingServicesRequest
  | addMarketingServicesSuccess
  | addMarketingServicesFailed
  | addAIMarketingServicesRequest
  | addAIMarketingServicesSuccess
  | addAIMarketingServicesFailed
  | markCampaignFavorite
  //----> FacebookTouchpoint
  | deleteFbTouchpointRequest
  | deleteFbTouchpointSuccess
  | deleteFbTouchpointFailed
  | setAvailablePrices
  //----> GoogleTouchpoint(Paid Search)
  | getFinalKeywordsRequest
  | getFinalKeywordsSuccess
  | getFinalKeywordsFailed
  //----> AmazonTouchpoint
  |toggleShareAmazonBudget
  //----> Checkout
  | processCampaignCheckoutRequest
  | processCampaignCheckoutSuccess
  | processCampaignCheckoutFailed
  //----> Clone Campaign
  | cloneCampaignRequest
  | cloneCampaignSuccess
  | cloneCampaignFailed

;
